import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import styled, { withTheme } from 'styled-components'
import Header from '../components/header'
import { VerticleaAligned } from '../components/styled'
import AboutContent from '../components/AboutContent'

const About = ({ location }) => (
  <Layout>
    <Header />
    <AboutContent showFullContent />
  </Layout>
)

export default About
